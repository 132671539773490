export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Auth',
    action: 'read',
  },
  {
    title: 'Admin',
    route: 'admin',
    icon: 'FileIcon',
    resource: 'Admin',
    action: 'read',
  },
]
